import type { MetaFunction } from "@remix-run/node";
import type { ServerRuntimeMetaArgs } from "@remix-run/server-runtime";
import { SEO_META_DEFAULT_CONFIG } from "~/config/seo.config";

export type SeoMetaDefaultConfig = {
  title: string;
  description: string;
  siteName: string;
  addSiteNameInTitle: boolean;
  siteNamePositionInTitle?: "start" | "end";
  siteNameSeparatorInTitle?: string;
  themeColor: string;
  structuredData?: SeoMetaStructuredData;
} & (
  | { cardImagePath: string; cardImageUrl?: never }
  | { cardImageUrl: string; cardImagePath?: never }
);

type SeoMetaConfig = Omit<
  SeoMetaDefaultConfig,
  | "siteName"
  | "addSiteNameInTitle"
  | "siteNameSeparatorInTitle"
  | "siteNamePositionInTitle"
  | "themeColor"
  | "cardImagePath"
  | "cardImageUrl"
  | "canonicalPath"
  | "canonicalUrl"
> & {
  addSiteNameInTitle?: boolean;
  noIndex?: boolean;
  noFollow?: boolean;
  selfCanonical?: boolean;
} & ({ cardImagePath?: string } | { cardImageUrl?: string }) &
  ({ canonicalPath?: string } | { canonicalUrl?: string });

type SeoMetaStructuredData = {
  "@context"?: string;
  "@type": string;
  url?: never;
  name?: string;
  alternateName?: string[];
  potentialAction?: {
    "@type": string;
    target: {
      "@type": string;
      urlTemplate: string;
    };
    "query-input": string;
  };
  [key: string]: unknown;
};

export function getSeoMeta(
  metaArgs: ServerRuntimeMetaArgs<unknown, Record<string, unknown>>,
  config: SeoMetaConfig
): ReturnType<MetaFunction> {
  // Default config values from root
  const {
    siteName,
    addSiteNameInTitle: defaultAddSiteNameInTitle,
    siteNamePositionInTitle = "start",
    siteNameSeparatorInTitle = " - ",
    themeColor = "#ffffff",
    title: defaultTitle,
    description: defaultDescription,
    structuredData: defaultStructuredData,
  } = SEO_META_DEFAULT_CONFIG;

  const title = config.title || defaultTitle;
  const description = config.description || defaultDescription;

  const addSiteNameInTitle =
    "addSiteNameInTitle" in config
      ? config.addSiteNameInTitle
      : defaultAddSiteNameInTitle;

  const structuredData =
    config.structuredData || defaultStructuredData
      ? {
          "@context": "https://schema.org",
          url: metaArgs.location.pathname,
          ...(config.structuredData || defaultStructuredData),
        }
      : null;

  const appUrl: string = import.meta.env.VITE_APP_URL;

  const routeUrl = appUrl + metaArgs.location.pathname;

  const selfCanonical = "selfCanonical" in config ? config.selfCanonical : true;

  const cardImageUrl =
    "cardImageUrl" in config
      ? config.cardImageUrl
      : "cardImagePath" in config
        ? appUrl + config.cardImagePath
        : "cardImageUrl" in SEO_META_DEFAULT_CONFIG
          ? SEO_META_DEFAULT_CONFIG.cardImageUrl
          : appUrl + SEO_META_DEFAULT_CONFIG.cardImagePath;

  const canonicalUrl =
    "canonicalUrl" in config
      ? config.canonicalUrl
      : "canonicalPath" in config
        ? appUrl + config.canonicalPath
        : selfCanonical
          ? routeUrl
          : null;

  const formatedTitle = addSiteNameInTitle
    ? siteNamePositionInTitle === "start"
      ? `${siteName}${siteNameSeparatorInTitle}${title}`
      : `${title}${siteNameSeparatorInTitle}${siteName}`
    : title;

  const seoMeta: ReturnType<MetaFunction> = [
    // Title / description
    { title: formatedTitle },
    { name: "description", content: description },

    // PWA
    { name: "application-name", content: siteName },
    { name: "apple-mobile-web-app-title", content: siteName },
    { name: "theme-color", content: themeColor },
    { name: "msapplication-TileColor", content: themeColor },

    // Open Graph card
    { property: "og:type", content: "website" },
    { property: "og:url", content: routeUrl },
    { property: "og:site_name", content: siteName },
    { property: "og:title", content: formatedTitle },
    { property: "og:description", content: description },
    { property: "og:image", content: cardImageUrl },

    // Twitter card
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:url", content: routeUrl },
    { name: "twitter:title", content: formatedTitle },
    { name: "twitter:description", content: description },
    { name: "twitter:image", content: cardImageUrl },
  ];

  if (structuredData) {
    seoMeta.push({ "script:ld+json": structuredData });
  }

  if (canonicalUrl) {
    seoMeta.push({ tagName: "link", rel: "canonical", href: canonicalUrl });
  }

  if (config.noIndex && config.noFollow) {
    seoMeta.push({ name: "robots", content: "noindex, nofollow" });
  } else if (config.noIndex) {
    seoMeta.push({ name: "robots", content: "noindex" });
  } else if (config.noFollow) {
    seoMeta.push({ name: "robots", content: "nofollow" });
  }

  return seoMeta;
}
