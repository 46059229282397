import type { SeoMetaDefaultConfig } from "~/utils/seo";

export const SEO_META_DEFAULT_CONFIG: SeoMetaDefaultConfig = {
  title: `TSI-SA Planning`,
  description: `TSI-SA Planning`,
  siteName: "TSI-SA Planning",
  addSiteNameInTitle: true,
  siteNamePositionInTitle: "end",
  siteNameSeparatorInTitle: " - ",
  cardImagePath: "/og-image.jpg",
  themeColor: "#0284c7",
};
